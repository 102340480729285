import { AudioSystem, SoundEffectComponent } from './audio';
import { FollowComponent, FollowSystem } from './follow';
import { GrabComponent, GrabSystem } from './grab';
import {
	Group,
	Mesh,
	MeshBasicMaterial,
	MeshMatcapMaterial,
	MeshStandardMaterial,
	PlaneGeometry,
	SRGBColorSpace,
	TextureLoader,
	Vector3,
} from 'three';
import { POINTER_MODE, PlayerComponent } from './player';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { GlobalComponent } from './global';
import { PointerSystem } from './pointer';
import { SpinSystem } from './spin';
import { System } from '@lastolivegames/becsy';
import { Text } from 'troika-three-text';

const textureLoader = new TextureLoader();

const CONFIG_PANEL_TEXTURE = textureLoader.load('assets/color_picker_ui.png');
CONFIG_PANEL_TEXTURE.colorSpace = SRGBColorSpace;

const kLeatherRoughness = 0.5;
const kLeatherMetalness = 0.0;
const kRubberRoughness = 0.7;
const kRubberMetalness = 0.7;

const ShoeMaterials = {
	BlackLeather: new MeshStandardMaterial({
		color: 0x050505,
		roughness: kLeatherRoughness,
		metalness: kLeatherMetalness,
		name: 'Black',
	}),
	WhiteLeather: new MeshStandardMaterial({
		color: 0xffffff,
		roughness: kLeatherRoughness,
		metalness: kLeatherMetalness,
		name: 'White',
	}),
	CobblestoneLeather: new MeshStandardMaterial({
		color: 0x979c99,
		roughness: kLeatherRoughness,
		metalness: kLeatherMetalness,
		name: 'Cobblestone',
	}),
	SportRedLeather: new MeshStandardMaterial({
		color: 0xa0072c,
		roughness: kLeatherRoughness,
		metalness: kLeatherMetalness,
		name: 'Sport Red',
	}),
	SailLeather: new MeshStandardMaterial({
		color: 0xd1c9bf,
		roughness: kLeatherRoughness,
		metalness: kLeatherMetalness,
		name: 'Sail',
	}),
	OldRoyalLeather: new MeshStandardMaterial({
		color: 0x161369,
		roughness: kLeatherRoughness,
		metalness: kLeatherMetalness,
		name: 'Old Royal',
	}),
	RoyalTintLeather: new MeshStandardMaterial({
		color: 0xa1bbe0,
		roughness: kLeatherRoughness,
		metalness: kLeatherMetalness,
		name: 'Royal Tint',
	}),
	PinkFoamLeather: new MeshStandardMaterial({
		color: 0xe8ced3,
		roughness: kLeatherRoughness,
		metalness: kLeatherMetalness,
		name: 'Pink Foam',
	}),
	KumquatLeather: new MeshStandardMaterial({
		color: 0xe48f3e,
		roughness: kLeatherRoughness,
		metalness: kLeatherMetalness,
		name: 'Kumquat',
	}),
	TourYellowLeather: new MeshStandardMaterial({
		color: 0xffd73d,
		roughness: kLeatherRoughness,
		metalness: kLeatherMetalness,
		name: 'Tour Yellow',
	}),
	LightBoneLeather: new MeshStandardMaterial({
		color: 0xedebde,
		roughness: kLeatherRoughness,
		metalness: kLeatherMetalness,
		name: 'Light Bone',
	}),
	MalachiteLeather: new MeshStandardMaterial({
		color: 0x316e56,
		roughness: kLeatherRoughness,
		metalness: kLeatherMetalness,
		name: 'Malachite',
	}),
	WhiteRubber: new MeshStandardMaterial({
		color: 0xffffff,
		roughness: kRubberRoughness,
		metalness: kRubberMetalness,
		name: 'White',
	}),
	BlackRubber: new MeshStandardMaterial({
		color: 0x050505,
		roughness: kRubberRoughness,
		metalness: kRubberMetalness,
		name: 'Black',
	}),
	GumRubber: new MeshStandardMaterial({
		color: 0x935f38,
		roughness: kRubberRoughness,
		metalness: kRubberMetalness,
		name: 'Gum',
	}),
	SailRubber: new MeshStandardMaterial({
		color: 0xd1c9bf,
		roughness: kRubberRoughness,
		metalness: kRubberMetalness,
		name: 'Sail',
	}),
	SportRedRubber: new MeshStandardMaterial({
		color: 0xa0072c,
		roughness: kRubberRoughness,
		metalness: kRubberMetalness,
		name: 'Sport Red',
	}),
	OldRoyalRubber: new MeshStandardMaterial({
		color: 0x161369,
		roughness: kRubberRoughness,
		metalness: kRubberMetalness,
		name: 'Old Royal',
	}),
	CobblestoneRubber: new MeshStandardMaterial({
		color: 0x979c99,
		roughness: kRubberRoughness,
		metalness: kRubberMetalness,
		name: 'Cobblestone',
	}),
};

const LeatherMaterials = [
	ShoeMaterials.BlackLeather,
	ShoeMaterials.WhiteLeather,
	ShoeMaterials.CobblestoneLeather,
	ShoeMaterials.SportRedLeather,
	ShoeMaterials.SailLeather,
	ShoeMaterials.OldRoyalLeather,
	ShoeMaterials.RoyalTintLeather,
	ShoeMaterials.PinkFoamLeather,
	ShoeMaterials.KumquatLeather,
	ShoeMaterials.TourYellowLeather,
	ShoeMaterials.LightBoneLeather,
	ShoeMaterials.MalachiteLeather,
];

const RubberMaterials = [
	ShoeMaterials.GumRubber,
	ShoeMaterials.BlackRubber,
	ShoeMaterials.WhiteRubber,
	ShoeMaterials.SailRubber,
	ShoeMaterials.CobblestoneRubber,
	ShoeMaterials.SportRedRubber,
];

// export const TEXTURE_TYPES = {
// 	Leather: 'leather',
// 	Canvas: 'Canvas',
// };

export const SHOE_PART_CONFIG_OPTIONS = {
	vamp: {
		displayName: 'Vamp',
		materials: LeatherMaterials,
		color: true,
		texture: true,
	},
	tip: {
		displayName: 'Tip',
		materials: LeatherMaterials,
		color: true,
		texture: true,
	},
	quarter: {
		displayName: 'Quarter',
		materials: LeatherMaterials,
		color: true,
		texture: true,
	},
	tongue: {
		displayName: 'Tongue',
		materials: LeatherMaterials,
		color: true,
		texture: true,
	},
	eyestay: {
		displayName: 'Eyestay',
		materials: LeatherMaterials,
		color: true,
		texture: true,
	},
	foxing_lining: {
		displayName: 'Foxing / Lining',
		materials: LeatherMaterials,
		color: true,
		texture: true,
	},
	swoosh_backtab: {
		displayName: 'Swoosh / Backtab',
		materials: LeatherMaterials,
		color: true,
		texture: true,
	},
	lace_dubrae: {
		displayName: 'Lace / Dubrae',
		materials: LeatherMaterials,
		color: true,
		texture: false,
	},
	midsole: {
		displayName: 'Midsole',
		materials: RubberMaterials,
		color: true,
		texture: false,
	},
	outsole: {
		displayName: 'Outsole',
		materials: RubberMaterials,
		color: true,
		texture: false,
	},
};

const prefab1 = {
	eyestay: 0,
	foxing_lining: 3,
	lace_dubrae: 3,
	midsole: 3,
	outsole: 3,
	quarter: 4,
	swoosh_backtab: 0,
	tip: 3,
	tongue: 4,
	vamp: 4,
};

const HIGHLIGHT_MATERIAL = new MeshMatcapMaterial({
	matcap: textureLoader.load('assets/matcap.png'),
});

export class ConfigUISystem extends System {
	constructor() {
		super();
		this.globalEntity = this.query((q) => q.current.with(GlobalComponent));
		this.playerEntity = this.query((q) => q.current.with(PlayerComponent));
		this.sneakerEntities = this.query((q) => q.current.with(GrabComponent));

		this.query((q) => q.current.using(FollowComponent).write);
		this.query((q) => q.current.using(SoundEffectComponent).write);

		this.schedule((s) =>
			s.before(
				FollowSystem,
				GrabSystem,
				SpinSystem,
				AudioSystem,
				PointerSystem,
			),
		);

		this._tempVec31 = new Vector3();
		this._tempVec32 = new Vector3();
	}

	execute() {
		const global = this.globalEntity.current[0].read(GlobalComponent);
		const player = this.playerEntity.current[0].read(PlayerComponent);

		for (const entity of this.sneakerEntities.current) {
			const grabComponent = entity.read(GrabComponent);
			const object = grabComponent.object3D;
			if (!object.userData.configUI) {
				const configUI = new Configurator(object);
				this.createEntity(FollowComponent, {
					object3D: configUI.uiPlane,
					followDistanceThreshold: 0.05,
					positionTarget: object,
					lookatTarget: player.head,
				});
				object.userData.configUI = configUI;
				global.scene.add(configUI.uiPlane);
			}

			const configUI = object.userData.configUI;

			object.traverse((child) => {
				if (child.isMesh) {
					child.material = child.userData.originalMaterial;
				}
			});

			let hasFreeController = false;
			if (grabComponent.attached) {
				Object.values(player.controllers).forEach((controllerObject) => {
					if (!controllerObject.attached) {
						hasFreeController = true;
						controllerObject.pointerMode = POINTER_MODE.Ray;
						const shoeIntersect = controllerObject.raycaster.intersectObjects(
							[object, configUI.uiPlane],
							true,
						)[0];
						if (shoeIntersect) {
							controllerObject.intersectDistance = shoeIntersect.distance;
							const shoepart =
								shoeIntersect.object.parent.name === 'mesh-prototype'
									? shoeIntersect.object
									: shoeIntersect.object.parent;
							if (
								Object.keys(SHOE_PART_CONFIG_OPTIONS).includes(shoepart.name)
							) {
								shoepart.traverse((child) => {
									if (child.isMesh) {
										child.material = HIGHLIGHT_MATERIAL;
									}
								});
								if (controllerObject.justStartedSelecting) {
									configUI.setShoePart(shoepart.name);
									this.createEntity(SoundEffectComponent, {
										type: 'click',
										sourceObject: shoeIntersect.object,
									});
									try {
										controllerObject.gamepadWrapper
											.getHapticActuator(0)
											?.pulse(0.1, 50);
									} catch {
										console.warn('no haptic actuator');
									}
								}
							} else if (shoeIntersect.object.name === 'color-swatch') {
								configUI.update(
									shoeIntersect.object.material_index,
									controllerObject.justStartedSelecting,
								);
								if (controllerObject.justStartedSelecting) {
									this.createEntity(SoundEffectComponent, {
										type: 'confirm',
										sourceObject: shoeIntersect.object,
									});
									try {
										controllerObject.gamepadWrapper
											.getHapticActuator(0)
											?.pulse(0.2, 100);
									} catch {
										console.warn('no haptic actuator');
									}
								}
							}
						}
					} else {
						controllerObject.pointerMode = POINTER_MODE.Claw;
					}
				});
			}

			configUI.uiPlane.visible =
				grabComponent.attached != null && hasFreeController;
		}
	}
}

class Configurator {
	constructor(sneakerObject) {
		this._uiElements = {
			partName: null,
		};

		this._sneakerObject = sneakerObject;
		this._tongueMesh = null;
		this._backtabMesh = null;
		this._tongueLabels = [];
		this._backtabLabels = [];
		this._tongueLabelBackings = [];

		let uiRoot = new Group();

		let box = new Mesh(
			// 800 x 592
			new PlaneGeometry(0.3, 0.2025), //BoxGeometry(0.2, 0.1, 0.005),
			new MeshBasicMaterial({
				color: 0xffffff,
				map: CONFIG_PANEL_TEXTURE,
				transparent: true,
			}),
		);
		uiRoot.add(box);

		this._uiElements.partName = new Text();
		this._uiElements.partName.text = 'PartName';
		this._uiElements.partName.fontSize = 0.026;
		this._uiElements.partName.color = 0xffffff;
		this._uiElements.partName.anchorX = 'center';
		this._uiElements.partName.anchorY = 'middle';
		this._uiElements.partName.position.z = 0.001;
		this._uiElements.partName.position.y = 0.056;
		this._uiElements.partName.sync();
		box.add(this._uiElements.partName);

		this._uiElements.colorName = new Text();
		this._uiElements.colorName.text = 'Color';
		this._uiElements.colorName.fontSize = 0.016;
		this._uiElements.colorName.color = 0xffffff;
		this._uiElements.colorName.anchorX = 'center';
		this._uiElements.colorName.anchorY = 'middle';
		this._uiElements.colorName.position.z = 0.001;
		this._uiElements.colorName.position.y = 0.0025;
		this._uiElements.colorName.sync();
		box.add(this._uiElements.colorName);
		box.position.set(0, 0.25, -0.2);

		const vamp = sneakerObject.getObjectByName('vamp');

		this._uiElements.colorSwatches = [];
		new GLTFLoader().load('assets/swatch.glb', (gltf) => {
			const swatchTile = gltf.scene.getObjectByName('swatch_tile');
			this._selectionRing = gltf.scene.getObjectByName('swatch_ring');
			box.add(this._selectionRing);
			this._selectionRing.visible = false;
			for (let j = 0; j < 2; ++j) {
				for (let i = 0; i < 6; ++i) {
					const swatch = new Mesh(
						swatchTile.geometry,
						new MeshStandardMaterial({
							color: 0xffffff,
							normalMap: vamp.material.normalMap,
						}),
					);
					swatch.position.x = -0.11 + 0.044 * i;

					let index = j * 6 + i;
					this.updateMaterial(swatch.material, LeatherMaterials[index]);
					swatch.material.roughness = 0.0;
					swatch.material.metalness = 0.1;
					swatch.name = 'color-swatch';
					swatch.material_index = index;
					swatch.visible = false;

					this._uiElements.colorSwatches.push(swatch);
					box.add(swatch);
				}
			}
		});

		// Set up the default colors
		let keys = Object.keys(SHOE_PART_CONFIG_OPTIONS);
		sneakerObject.traverse((child) => {
			if (child.isMesh) {
				child.userData.originalMaterial = child.material;
				child.userData.colorIndex = 0;
				// console.log('Look for material by name: ' + child.name);
				for (var i = 0; i < keys.length; i++) {
					let key = keys[i];
					if (child.name.includes(key)) {
						child.userData.colorIndex = prefab1[key];

						child.userData.key = key;
						this.updateMaterial(
							child.userData.originalMaterial,
							SHOE_PART_CONFIG_OPTIONS[key].materials[
								child.userData.colorIndex
							],
						);
						this.setMaterialOnShoePartAndChildren(
							child,
							child.userData.originalMaterial,
						);
					}
				}

				if (child.name == 'tongue_1') {
					this._tongueMesh = child;
				} else if (child.name == 'swoosh_backtab_2') {
					this._backtabMesh = child;
				} else if (
					child.name == 'tongue_label_left' ||
					child.name == 'tongue_label_right'
				) {
					this._tongueLabels.push(child);
				} else if (
					child.name == 'backtab_logo_left' ||
					child.name == 'backtab_logo_right' ||
					child.name == 'backtab_text_left' ||
					child.name == 'backtab_text_right'
				) {
					this._backtabLabels.push(child);
				} else if (child.name == 'tongue_1_2') {
					this._tongueLabelBackings.push(child);
				}

				// default material -- for interior of shoe, backtab label/logo
				if (child.userData.key === undefined && child.name != 'vholes') {
					child.userData.key = 'unknown';
					// console.log('NOT FOUND');
					this.updateMaterial(
						child.userData.originalMaterial,
						ShoeMaterials.CobblestoneLeather,
					);
				}
			}
		});

		this._setLogoColors();
		this._shoepartSelected = null;

		console.log(uiRoot);
		this._plane = uiRoot;
	}

	updateMaterial(dest, src) {
		dest.color = src.color;
		dest.roughness = src.roughness;
		dest.metalness = src.metalness;
		dest.name = src.name;
	}

	_isSpecialCaseMesh(meshName) {
		if (
			meshName == 'tongue_label_left' ||
			meshName == 'tongue_label_right' ||
			meshName == 'backtab_logo_left' ||
			meshName == 'backtab_logo_right' ||
			meshName == 'backtab_text_left' ||
			meshName == 'backtab_text_right'
		) {
			return true;
		}

		return false;
	}

	setMaterialOnShoePartAndChildren(shoepart, material) {
		shoepart.traverse((child) => {
			if (child.isMesh) {
				//console.log('\t' + child.name);
				//child.userData.originalMaterial = material;
				this.updateMaterial(child.userData.originalMaterial, material);

				// if (this._isSpecialCaseMesh(child.name)) {
				// 	child.userData.originalMaterial; // do nothing
				// 	// child.userData.original	Material.color.setRGB(1, 0, 1);
				// } else {
				// 	child.userData.originalMaterial = material;
				// }
			}
		});
	}

	_setLogoColors() {
		let tongueColorIndex = this._tongueMesh.userData.colorIndex;
		let backtabColorIndex = this._backtabMesh.userData.colorIndex;

		// 0 ShoeMaterials.BlackLeather,
		// ShoeMaterials.WhiteLeather,
		// ShoeMaterials.CobblestoneLeather,
		// 3 ShoeMaterials.SportRedLeather,
		// ShoeMaterials.SailLeather,
		// 5 ShoeMaterials.OldRoyalLeather,
		// ShoeMaterials.RoyalTintLeather,
		// ShoeMaterials.PinkFoamLeather,
		// 8 ShoeMaterials.KumquatLeather,
		// ShoeMaterials.TourYellowLeather,
		// ShoeMaterials.LightBoneLeather,
		// ShoeMaterials.MalachiteLeather,

		let bUseWhiteTongueLabel =
			tongueColorIndex == 0 ||
			tongueColorIndex == 3 ||
			tongueColorIndex == 5 ||
			tongueColorIndex == 8;

		let bUseWhiteBackLabel =
			backtabColorIndex == 0 ||
			backtabColorIndex == 3 ||
			backtabColorIndex == 5 ||
			backtabColorIndex == 8;

		// lable backing
		for (let i = 0; i < this._tongueLabelBackings.length; i++) {
			this.updateMaterial(
				this._tongueLabelBackings[i].userData.originalMaterial,
				bUseWhiteTongueLabel
					? ShoeMaterials.WhiteLeather
					: ShoeMaterials.BlackLeather,
			);
		}

		// label text
		for (let i = 0; i < this._tongueLabels.length; i++) {
			this.updateMaterial(
				this._tongueLabels[i].userData.originalMaterial,
				bUseWhiteTongueLabel
					? ShoeMaterials.BlackLeather
					: ShoeMaterials.WhiteLeather,
			);
		}
		for (let i = 0; i < this._backtabLabels.length; i++) {
			this.updateMaterial(
				this._backtabLabels[i].userData.originalMaterial,
				bUseWhiteBackLabel
					? ShoeMaterials.WhiteLeather
					: ShoeMaterials.BlackLeather,
			);
		}
	}

	get uiPlane() {
		return this._plane;
	}

	setShoePart(partName) {
		this._shoepartSelected = this._sneakerObject.getObjectByName(partName);
		this._uiElements.partName.text =
			SHOE_PART_CONFIG_OPTIONS[partName].displayName;
		this._uiElements.partName.sync();

		this._updateColorNameUI();
		this._updateColorSwatchesUI();

		// this._logPrefab();
	}

	_logPrefab() {
		let keys = Object.keys(SHOE_PART_CONFIG_OPTIONS);

		console.log('SNEAKER PREFAB {');
		this._sneakerObject.traverse((child) => {
			if (child.isMesh) {
				for (var i = 0; i < keys.length; i++) {
					let key = keys[i];
					if (child.name.includes(key)) {
						console.log(key + ': ' + child.userData.colorIndex + ', ');
					}
				}
			}
		});
		console.log('}');
	}

	_updateColorNameUI() {
		let mesh;
		if (this._shoepartSelected.isMesh) {
			mesh = this._shoepartSelected;
		} else {
			this._shoepartSelected.traverse((child) => {
				if (child.isMesh) {
					mesh = child;
				}
			});
		}

		if (mesh !== undefined) {
			this._uiElements.colorName.text = mesh.userData.originalMaterial.name;
		} else {
			this._uiElements.colorName.text = 'Unknown';
		}
		this._uiElements.colorName.sync();
	}

	_updateColorSwatchesUI() {
		// Get a mesh so we can get the materials
		let mesh;
		if (this._shoepartSelected.isMesh) {
			mesh = this._shoepartSelected;
		} else {
			this._shoepartSelected.traverse((child) => {
				if (child.isMesh) {
					mesh = child;
				}
			});
		}

		this._selectionRing.visible = false;

		let configOptions = SHOE_PART_CONFIG_OPTIONS[mesh.userData.key];

		let bSingleRow = configOptions.materials.length == 6;
		for (let i = 0; i < this._uiElements.colorSwatches.length; ++i) {
			let swatch = this._uiElements.colorSwatches[i];
			if (i < configOptions.materials.length) {
				swatch.visible = true;
				swatch.position.z = 0;
				this.updateMaterial(swatch.material, configOptions.materials[i]);
				if (bSingleRow) {
					swatch.position.y = -0.052;
				} else {
					swatch.position.y = i > 5 ? -0.074 : -0.03;
				}
				if (i == mesh.userData.colorIndex) {
					this._selectionRing.position.copy(swatch.position);
					this._selectionRing.visible = true;
				}
			} else {
				swatch.position.z = -0.5;
				swatch.visible = false;
			}
		}
	}

	update(colorIndex, justClicked) {
		if (justClicked && this._shoepartSelected) {
			this._shoepartSelected.traverse((child) => {
				if (child.isMesh) {
					child.userData.colorIndex = colorIndex;
					const srcMat =
						SHOE_PART_CONFIG_OPTIONS[this._shoepartSelected.name].materials[
							colorIndex
						];
					if (srcMat) {
						this.updateMaterial(child.userData.originalMaterial, srcMat);
						this.setMaterialOnShoePartAndChildren(
							this._shoepartSelected,
							child.userData.originalMaterial,
						);
					}
				}
			});

			this._updateColorNameUI();
			this._updateColorSwatchesUI();
			this._setLogoColors();
		}
	}
}
