import { System, Type } from '@lastolivegames/becsy';

import { Vector3 } from 'three';

export class FollowComponent {}

FollowComponent.schema = {
	object3D: { type: Type.object },
	followDistanceThreshold: { type: Type.float32 },
	positionTarget: { type: Type.object },
	lookatTarget: { type: Type.object },
};

export class FollowSystem extends System {
	constructor() {
		super();
		this.followEntities = this.query((q) => q.current.with(FollowComponent));
		this._vec3 = new Vector3();
	}

	execute() {
		for (const entity of this.followEntities.current) {
			const {
				object3D,
				positionTarget,
				lookatTarget,
				followDistanceThreshold,
			} = entity.read(FollowComponent);
			positionTarget.getWorldPosition(this._vec3);
			if (object3D.position.distanceTo(this._vec3) > followDistanceThreshold) {
				object3D.position.lerp(this._vec3, this.delta);
				lookatTarget.getWorldPosition(this._vec3);
				this._vec3.y = object3D.position.y;
				object3D.lookAt(this._vec3);
			}
		}
	}
}
