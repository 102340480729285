import { System, Type } from '@lastolivegames/becsy';

import { GlobalComponent } from './global';
import { PlayerComponent } from './player';
import { Vector3 } from 'three';

export class GrabComponent {}

GrabComponent.schema = {
	object3D: { type: Type.object },
	attached: { type: Type.object, default: null },
	justAttached: { type: Type.boolean, default: false },
	justDetached: { type: Type.boolean, default: false },
	onSurface: { type: Type.boolean, default: false },
};

export class GrabSystem extends System {
	constructor() {
		super();
		this.globalEntity = this.query((q) => q.current.with(GlobalComponent));
		this.playerEntity = this.query((q) => q.current.with(PlayerComponent));
		this.sneakerEntities = this.query(
			(q) => q.current.with(GrabComponent).write,
		);

		this._vec3 = new Vector3();
		this._vec32 = new Vector3();
		this._vec33 = new Vector3();
	}

	execute() {
		const global = this.globalEntity.current[0].read(GlobalComponent);
		const player = this.playerEntity.current[0].read(PlayerComponent);

		const attachMap = {};

		Object.values(player.controllers).forEach((controllerObject) => {
			// const { raycaster } = controllerObject;
			const { targetRaySpace } = controllerObject;
			if (!controllerObject.attached) {
				targetRaySpace.getWorldPosition(this._vec3);
				const intersectObjects = this.sneakerEntities.current
					.filter((entity) => !entity.read(GrabComponent).attahced)
					.map((entity) => entity.read(GrabComponent).object3D)
					.sort(
						(a, b) =>
							a.getWorldPosition(this._vec32).distanceTo(this._vec3) -
							b.getWorldPosition(this._vec33).distanceTo(this._vec3),
					);

				if (intersectObjects[0]) {
					const localVec3 = intersectObjects[0].worldToLocal(this._vec3);
					if (
						localVec3.x > 0.02 - 0.18 &&
						localVec3.x < 0.02 + 0.18 &&
						localVec3.y > 0.035 - 0.1 &&
						localVec3.y < 0.035 + 0.1 &&
						localVec3.z > -0.08 &&
						localVec3.z < 0.08
					) {
						attachMap[intersectObjects[0].userData.shoeId] = controllerObject;
					}
				}
			}
		});

		for (const entity of this.sneakerEntities.current) {
			const grabComponent = entity.write(GrabComponent);
			grabComponent.justAttached = false;
			grabComponent.justDetached = false;
			const object = grabComponent.object3D;
			const shoeId = object.userData.shoeId;

			if (!grabComponent.attached) {
				const controllerObject = attachMap[shoeId];
				if (controllerObject && controllerObject.justStartedSelecting) {
					grabComponent.attached = controllerObject;
					grabComponent.justAttached = true;
					controllerObject.targetRaySpace.attach(object);
					controllerObject.attached = true;
				}
			} else {
				const controllerObject = grabComponent.attached;
				if (controllerObject.justStoppedSelecting) {
					global.scene.attach(object);
					grabComponent.attached = null;
					grabComponent.justDetached = true;
					controllerObject.attached = false;
				}
			}
		}
	}
}
