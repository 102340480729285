import {
	Group,
	Mesh,
	MeshBasicMaterial,
	Object3D,
	PlaneGeometry,
	SRGBColorSpace,
	TextureLoader,
} from 'three';

import { ConfigUISystem } from './configUI';
import { FollowComponent } from './follow';
import { GlobalComponent } from './global';
import { GrabComponent } from './grab';
import { PlayerComponent } from './player';
import { SpinComponent } from './spin';
import { System } from '@lastolivegames/becsy';

export class WelcomeSystem extends System {
	constructor() {
		super();
		this.globalEntity = this.query((q) => q.current.with(GlobalComponent));
		this.playerEntity = this.query((q) => q.current.with(PlayerComponent));
		this.sneakerEntities = this.query(
			(q) => q.current.using(GrabComponent).write,
		);
		this.query((q) => q.current.using(FollowComponent).write);
		this.query((q) => q.current.using(SpinComponent).write);
		this.schedule((s) => s.before(ConfigUISystem));

		this._welcomePanel = null;
		this._sneakerObjects = {
			left: null,
			right: null,
		};

		this._sneakersGroup = null;
		this._sneakersDetached = false;
	}

	execute() {
		const global = this.globalEntity.current[0].read(GlobalComponent);
		const player = this.playerEntity.current[0].read(PlayerComponent);
		const sneakerPrototype = global.scene.getObjectByName('mesh-prototype');
		if (!this._welcomePanel && sneakerPrototype) {
			const geometry = new PlaneGeometry(0.5, 0.12);
			const material = new MeshBasicMaterial({
				transparent: true,
			});
			new TextureLoader().load('assets/intro_panel.png', (texture) => {
				texture.colorSpace = SRGBColorSpace;
				material.map = texture;
			});
			const plane = new Mesh(geometry, material);
			this._welcomePanel = plane;
			global.scene.add(this._welcomePanel);
			const uiAnchor = new Object3D();
			uiAnchor.position.set(0, 0, -0.5);
			player.head.add(uiAnchor);

			this.createEntity(FollowComponent, {
				object3D: this._welcomePanel,
				followDistanceThreshold: 0.1,
				positionTarget: uiAnchor,
				lookatTarget: player.head,
			});

			const sneakerLeft = new Group().add(sneakerPrototype.clone());
			sneakerLeft.children[0].position.y += 0.028;
			sneakerLeft.position.set(0, 0, -0.075);
			// const cube = new Mesh(
			// 	new BoxGeometry(0.32, 0.16, 0.12),
			// 	new MeshBasicMaterial({ wireframe: true }),
			// );
			// cube.position.y = 0.035;
			// cube.position.x = 0.02;
			// sneakerLeft.add(cube);
			sneakerLeft.traverse((child) => {
				if (child.isMesh) {
					child.userData.shoeId = 'left';
					if (
						child.name == 'sockliner_logo_left' ||
						child.name == 'tongue_label_left' ||
						child.name == 'backtab_logo_left' ||
						child.name == 'backtab_text_left' ||
						child.name == 'midsole_1_1'
					) {
						child.visible = true;
					} else if (
						child.name == 'sockliner_logo_right' ||
						child.name == 'tongue_label_right' ||
						child.name == 'backtab_logo_right' ||
						child.name == 'backtab_text_right' ||
						child.name == 'rt_midsole_accent'
					) {
						child.visible = false;
					}
				}
			});
			sneakerLeft.userData.shoeId = 'left';
			this.createEntity(GrabComponent, { object3D: sneakerLeft });

			const sneakerRight = new Group().add(sneakerPrototype.clone());
			sneakerRight.children[0].position.y += 0.028;
			sneakerRight.scale.set(1, 1, -1);
			sneakerRight.position.set(0, 0, 0.075);
			sneakerRight.traverse((child) => {
				if (child.isMesh) {
					child.userData.shoeId = 'right';
					if (
						child.name == 'sockliner_logo_left' ||
						child.name == 'tongue_label_left' ||
						child.name == 'backtab_logo_left' ||
						child.name == 'backtab_text_left' ||
						child.name == 'midsole_1_1'
					) {
						child.visible = false;
					} else if (
						child.name == 'sockliner_logo_right' ||
						child.name == 'tongue_label_right' ||
						child.name == 'backtab_logo_right' ||
						child.name == 'backtab_text_right' ||
						child.name == 'rt_midsole_accent'
					) {
						child.visible = true;
					}
				}
			});
			sneakerRight.userData.shoeId = 'right';
			this.createEntity(GrabComponent, { object3D: sneakerRight });

			const sneakers = new Group().add(sneakerLeft, sneakerRight);
			sneakers.position.y = -0.25;
			this._welcomePanel.add(sneakers);
			this.createEntity(SpinComponent, {
				object3D: sneakers,
			});
			this._sneakersGroup = sneakers;
		}

		if (this._welcomePanel) {
			let isAttached = false;
			Object.values(player.controllers).forEach((controllerObject) => {
				if (controllerObject.attached) {
					isAttached = true;
				}
			});
			if (isAttached && !this._sneakersDetached) {
				[...this._sneakersGroup.children].forEach((sneakerObject) => {
					global.scene.attach(sneakerObject);
				});
				this._sneakersDetached = true;
			}
			this._welcomePanel.visible =
				global.renderer.xr.isPresenting && !isAttached;
		}
	}
}
