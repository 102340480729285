import { System, Type } from '@lastolivegames/becsy';

import { Howl } from 'howler';
import { PlayerComponent } from './player';
import { Vector3 } from 'three';

export class SoundEffectComponent {}

SoundEffectComponent.schema = {
	type: {
		type: Type.staticString(['confirm', 'maximize', 'minimize', 'click']),
	},
	sourceObject: { type: Type.object },
};

export class AudioSystem extends System {
	constructor() {
		super();
		this.playerEntity = this.query((q) => q.current.with(PlayerComponent));
		this.soundEffectEntities = this.query(
			(q) => q.current.with(SoundEffectComponent).write,
		);
		this.soundEffects = {
			confirm: new Howl({ src: ['assets/audio/confirm.webm'] }),
			maximize: new Howl({ src: ['assets/audio/maximize.webm'] }),
			minimize: new Howl({ src: ['assets/audio/minimize.webm'] }),
			click: new Howl({ src: ['assets/audio/click.webm'] }),
		};
		this._vec3 = new Vector3();
	}

	execute() {
		const player = this.playerEntity.current[0].read(PlayerComponent);
		[...this.soundEffectEntities.current].forEach((entity) => {
			const soundEffectComponent = entity.read(SoundEffectComponent);
			const soundEffect = this.soundEffects[soundEffectComponent.type];
			const soundInstanceId = soundEffect.play();
			const localPosition = player.head.worldToLocal(
				soundEffectComponent.sourceObject.getWorldPosition(this._vec3),
			);
			soundEffect.pos(...localPosition.toArray(), soundInstanceId);
			soundEffect.volume(1, soundInstanceId);
			entity.delete();
		});
	}
}
